import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {
  fetchAnalysisChart,
  fetchAnalysisOverview,
  fetchAnalysisReconciliationOverview,
  fetchExternalTransactions,
  fetchTransactions,
} from '../../../../requests/queries/reconciliations';

const useOverview = ({entity_id, id, ...rest}) => {
  const params = {
    [entity_id]: id,
    ...rest,
  };
  const {data: overviewData, isLoading: overviewLoading} = useQuery(
    ['overview', {...params}],
    () => fetchAnalysisOverview({params}),
    {enabled: !!id},
  );

  function calculateInflow(transactions, transferTypes) {
    return transactions
      .filter(t => transferTypes.includes(t.transaction_type))
      .reduce(
        (acc, t) => {
          acc.amount += t.amount;
          acc.fee_amount += t.fee_amount || 0;
          acc.count += parseInt(t.count, 10);
          return acc;
        },
        {amount: 0, fee_amount: 0, count: 0},
      );
  }

  const overviewAnalysis = useMemo(
    () => (!overviewLoading && overviewData?.entity) || [],
    [overviewLoading, overviewData],
  );

  const inFlow = useMemo(
    () =>
      !overviewLoading &&
      calculateInflow(overviewAnalysis, ['credit', 'deposit']),
    [overviewAnalysis, overviewLoading],
  );

  const outFlow = useMemo(
    () =>
      !overviewLoading &&
      calculateInflow(overviewAnalysis, ['debit', 'withdraw', 'withdrawal']),

    [overviewAnalysis, overviewLoading],
  );
  return {overviewAnalysis, inFlow, outFlow, overviewLoading};
};
const useAnalysisChart = ({entity_id, id, ...rest}) => {
  const params = {
    [entity_id]: id,
    ...rest,
  };
  const {data: chartData, isLoading: chartLoading} = useQuery(
    ['analysis-chart', {...params}],
    () => fetchAnalysisChart({params}),
    {enabled: !!id},
  );

  const analysisChart = useMemo(
    () => (!chartLoading && chartData?.entity) || [],
    [chartData, chartLoading],
  );
  return {analysisChart, chartLoading};
};
const useOverviewRecon = ({entity_id, id}) => {
  const params = {
    [entity_id]: id,
    period: 'month',
  };
  const {data: reconData, isLoading: reconLoading} = useQuery(
    ['recon-overview', {...params}],
    () => fetchAnalysisReconciliationOverview({params}),
    {enabled: !!id},
  );

  const analysisRecon = useMemo(
    () => (!reconLoading && reconData?.entity) || [],
    [reconData, reconLoading],
  );
  return {analysisRecon, reconLoading};
};

const useTransactions = ({
  id,
  entity_id,
  tabType,
  page,
  limit,
  transaction_recon,
  ...rest
}) => {
  const params = {
    [entity_id]: id,
    ...(transaction_recon ? {transaction_recon} : {}),
    page,
    limit,
    ...rest,
  };
  const {
    data: companyTransactions,
    isLoading: transactionsLoading,
    isFetching: transactionsFetching,
  } = useQuery(
    ['company-transactions', {...params}],
    () => fetchTransactions({params}),
    {
      enabled: !!id && tabType === 'internal',
    },
  );

  const {
    data: companyExternalTransactions,
    isLoading: externalTransactionsLoading,
    isFetching: externalTransactionsFetching,
  } = useQuery(
    ['external-company-transactions', {...params}],
    () => fetchExternalTransactions({params}),
    {
      enabled: !!id && tabType === 'external',
    },
  );

  const isLoading = transactionsLoading || externalTransactionsLoading;
  const isFetching = transactionsFetching || externalTransactionsFetching;
  const tableData =
    !isLoading && tabType === 'internal'
      ? companyTransactions
      : companyExternalTransactions;

  return {tableData, isLoading, isFetching};
};

export {useOverview, useTransactions, useAnalysisChart, useOverviewRecon};
