import moment from 'moment';

const chartPeriod = [
  {value: 'day', label: 'Day', name: 'date'},
  {value: 'week', label: 'Week', name: 'date'},
  {value: 'month', label: 'Month', name: 'date'},
  {value: 'quarter', label: 'Quarter', name: 'date'},
  {value: 'year', label: 'Year', name: 'date'},
];
const verificationChartPeriod = [
  {value: 'daily', label: 'Daily', name: 'date'},
  {value: 'weekly', label: 'Weekly', name: 'date'},
  {value: 'monthly', label: 'Monthly', name: 'date'},
  {value: 'quarterly', label: 'Quarterly', name: 'date'},
  {value: 'yearly', label: 'Yearly', name: 'date'},
];

const transactionType = [
  {value: 'credit', label: 'Credit', name: 'type'},
  {value: 'debit', label: 'Debit', name: 'type'},
];
const requiredFileUploadHeaders = [
  'transaction_amount',
  'transaction_date',
  'transaction_time',
  'transaction_type',
  'reference_id',
];
const defaultFileUploadHeaders = [
  'transaction_amount',
  'transaction_date',
  'transaction_time',
  'transaction_type',
  'reference_id',
  'session_id',
  'user_phone',
  'user_email',
  'counterparty_account_name',
  'counterparty_account_number',
  'counterparty_bank_name',
  'counterparty_country',
  'transaction_fee',
  'user_id',
  'user_name',
];
const sumTransactionsAmount = transactions => {
  if (!Array.isArray(transactions) || transactions.length === 0) return 0;
  return transactions.reduce(
    (acc, tran) => acc + (tran?.transaction_amount || 0),
    0,
  );
};
const getPeriod = (period, periodType) => {
  const date = moment(period);
  switch (periodType?.toLowerCase()) {
    case 'day':
    case 'daily':
      return [date.format('ddd'), date.format('D/MM')];
    case 'week':
    case 'weekly':
      return [`Wk ${date.isoWeek()}`, date.year()];
    case 'month':
    case 'monthly':
      return [date.format('MMM'), date.year()];

    case 'quarter':
    case 'quarterly':
      return [`qtr - ${date.quarter()}`, date.year()];
    case 'year':
    case 'yearly':
      return date.year();

    default:
      return date.format('MMM');
  }
};
const initialFilters = {
  date_from: '',
  date_to: '',
  account: '',
};
export {
  chartPeriod,
  getPeriod,
  initialFilters,
  sumTransactionsAmount,
  transactionType,
  defaultFileUploadHeaders,
  requiredFileUploadHeaders,
  verificationChartPeriod,

};

