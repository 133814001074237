import {api} from '../../helpers';

function fetchIndividualLookup(params) {
  return api
    .get('/users/widget/lookup/individual', {params})
    .then(res => res.data);
}

function fetchGlobalSearch(params) {
  return api
    .get('/users/widget/lookup/business/detail', {params})
    .then(res => res.data);
}

export {fetchIndividualLookup, fetchGlobalSearch};
