import React from 'react';

function TransactionTab({tabType, setTabType, setQuery, query}) {
  return (
    <div>
      <div className="border w-fit font-inter p-1 border-[#E1E4EA] rounded-xl bg-[#F7F7F7] flex items-center gap-2 text-xs lg:text-tiny font-medium">
        <div
          className={`${
            tabType === 'internal'
              ? 'bg-white text-black'
              : 'bg-transparent text-[#868C98]'
          } cursor-pointer  rounded-lg px-4 py-[10px] transition hover:bg-white text-black`}
          onClick={() => {
            setTabType('internal');
            setQuery({...query, page: 1});
          }}
        >
          Expected Payment
        </div>
        <div
          className={`${
            tabType === 'external'
              ? 'bg-white text-black'
              : 'bg-transparent text-[#868C98]'
          } cursor-pointer  rounded-lg px-4 py-[10px] transition hover:bg-white text-black`}
          onClick={() => setTabType('external')}
        >
          Transactions
        </div>
      </div>
    </div>
  );
}

const TransactionTabs = React.memo(TransactionTab);

export {TransactionTabs};
