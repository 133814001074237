import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import {connect} from 'react-redux';
import QuickActionsCard from './QuickActionsCard';
import QuickActionsDynamicCard from './QuickActionsDynamicCard';
import QuickActionsChecklist from './QuickActionsChecklist';
import QuickActionsWrapper from './QuickActionsWrapper';
import quickActionsGif from '../../assets/images/kycWidgetGif.gif';
import Loader from '../../components/Loader';
import {Constants} from '../../helpers';
import WelcomeModals from './WelcomeModals';
import * as Actions from '../../store/actions';
import TourMenu from './TourMenu';
import {
  QuickActionsTopup,
  QuickActionsLookup,
  QuickActionsOnboard,
  Unchecked,
  Checked,
} from '../../components/images';
import {PageContext} from '../../helpers/context';
import {
  formatNumberToCurrency,
  formatUsd,
} from '../../helpers/formatNumberToCurrency';
import {SecondaryButton, TopupModal} from '../../components';

function QuickActions({auth: {userDetails, loading, user}, updateUserProfile}) {
  const [openWelcomeModals, setOpenWelcomeModals] = useState(false);
  const [openTopUp, setOpenTopUp] = useState(false);
  const [openTour, setOpenTour] = useState(false);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('quickactions');
  }, [setPageName]);

  const hasCompletedChecklist = useMemo(() => {
    const updatedUserDetails = userDetails || {};
    const updatedChecklist =
      updatedUserDetails.checklist &&
      Object.keys(updatedUserDetails.checklist).reduce((acc, key) => {
        if (key !== 'verification') {
          acc[key] = updatedUserDetails.checklist[key];
        }
        return acc;
      }, {});
    const updatedDetails = {
      ...updatedUserDetails,
      checklist: updatedChecklist,
    };
    return (
      updatedDetails &&
      updatedDetails.checklist &&
      Object.values(updatedDetails.checklist).every(check => check)
    );
  }, [userDetails]);

  const permittedLinks = useCallback(
    (permissions = {}) => {
      if (userDetails?.permissions?.role !== 'Owner') {
        return Object.values(permissions).some(value => value);
      }
      return true;
    },
    [userDetails],
  );

  useEffect(() => {
    if (user?.first_time_user) setOpenWelcomeModals(true);
  }, [user]);

  const disableLookup = !permittedLinks(
    userDetails?.permissions?.lookupPermissions,
  );

  const manageOnboardFlows =
    userDetails?.permissions?.onboardPermissions?.manageFlows;
  const viewOnboardFlows =
    userDetails?.permissions?.onboardPermissions?.viewFlows;
  const topUpPermissions =
    userDetails?.permissions?.quickActionPermissions?.topup;

  const disableOnboard = !manageOnboardFlows && !viewOnboardFlows;

  return (
    <>
      <QuickActionsWrapper
        topup={
          userDetails?.checklist?.topup && topUpPermissions ? (
            <div className="items-center hidden gap-4 sm:flex">
              <div className="bg-white80 flex items-center gap-2 px-2 pt-[6px] pb-[2px] rounded-full">
                <span
                  data-testid="wallet-balance"
                  className="text-sm font-medium text-grey"
                >
                  Wallet Balance:{' '}
                  {userDetails &&
                  userDetails?.company &&
                  userDetails?.company?.currency === 'USD'
                    ? formatUsd(userDetails?.company?.wallet_balance / 100 ?? 0)
                    : formatNumberToCurrency(
                        userDetails?.company?.wallet_balance / 100 ?? 0,
                        userDetails &&
                          userDetails.company &&
                          userDetails.company.currency,
                      )}
                </span>
              </div>
              <SecondaryButton
                buttonText="Quick top-up"
                className="text-xs leading-3"
                xPadding="px-2"
                yPadding="py-2"
                btnHeight="h-auto"
                onClick={() => setOpenTopUp(!openTopUp)}
              />
            </div>
          ) : null
        }
      >
        <TopupModal open={openTopUp} setOpen={setOpenTopUp} />

        <WelcomeModals
          closeModal={() => setOpenWelcomeModals(false)}
          open={openWelcomeModals}
          setOpen={setOpenWelcomeModals}
          updateUserProfile={updateUserProfile}
        />
        <TourMenu open={openTour} setOpen={setOpenTour} />
        {loading ? (
          <Loader height={80} />
        ) : (
          <div className="flex flex-col gap-8 mt-8 sm:gap-6">
            <div className="flex flex-wrap gap-4 sm:flex-nowrap">
              <QuickActionsCard
                imageComponent={<QuickActionsTopup />}
                heading="Top up wallet"
                paragraph="Fund your wallet via online payment or bank transfer."
                linkText="Top up"
                link="/settings/billing"
              />

              <QuickActionsCard
                imageComponent={<QuickActionsLookup />}
                heading="Government Lookup"
                paragraph="Perform multiple identity lookup with ease."
                linkText="Perform a Lookup"
                link={
                  permittedLinks(userDetails?.permissions?.lookupPermissions)
                    ? '/easy-lookup/individual'
                    : '/quick-actions'
                }
                disabled={
                  userDetails?.permissions?.role === 'Owner'
                    ? undefined
                    : disableLookup
                }
              />
              <QuickActionsCard
                imageComponent={<QuickActionsOnboard />}
                heading="Easy Onboard"
                paragraph="Create onboarding flows to verify the identity of your users."
                linkText="Create a flow"
                link={
                  permittedLinks(userDetails?.permissions?.onboardPermissions)
                    ? '/easy-onboard'
                    : '/quick-actions'
                }
                disabled={
                  userDetails?.permissions?.role === 'Owner'
                    ? undefined
                    : disableOnboard
                }
              />
            </div>

            <div className="flex flex-col gap-6 lg:flex-row sm:gap-4 basis-full">
              {hasCompletedChecklist ? (
                <div className="py-6 px-4 lg:px-8 md:py-[21px] bg-white rounded basis-[67.2%]">
                  <div className="flex flex-wrap sm:flex-nowrap items-center gap-8 justify-center sm:justify-start h-full">
                    <div className="">
                      <h4 className="text-base text-body font-medium">
                        Quick tour
                      </h4>
                      <p className="mt-6 text-grey text-lg font-medium">
                        Take a Quick Tour
                      </p>
                      <p className="mt-2 text-sm text-body">
                        Take a quick tour of our features to help you easily
                        navigate the application and sign up real users fast
                      </p>

                      <button
                        className="inline-block mt-4 text-brandBlue text-sm font-medium p-4"
                        onClick={() => setOpenTour(true)}
                      >
                        View tour
                      </button>
                    </div>

                    <div className="aspect-square w-full">
                      <img src={quickActionsGif} alt="" />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="px-4 py-8 lg:p-8 bg-white rounded basis-[67.2%]">
                  <div className="flex items-center justify-between">
                    <h4 className="text-base font-medium text-grey">
                      Checklist
                    </h4>
                  </div>

                  <div className="flex flex-col gap-6 mt-6 w-fit">
                    <QuickActionsChecklist
                      imageComponent={<Checked />}
                      heading="Sign Up"
                      paragraph="Sign up to start verifying identities."
                    />
                    <QuickActionsChecklist
                      imageComponent={
                        userDetails?.checklist?.topup ? (
                          <Checked />
                        ) : (
                          <Unchecked />
                        )
                      }
                      heading="Top-up wallet"
                      paragraph="Add money to your wallet to start making API calls."
                      link={
                        userDetails?.permissions?.role === 'Owner'
                          ? Constants.PAGES_URL.SETTINGS_BILLING
                          : '/quick-actions'
                      }
                    />
                    <QuickActionsChecklist
                      imageComponent={
                        userDetails?.checklist?.createApp ? (
                          <Checked />
                        ) : (
                          <Unchecked />
                        )
                      }
                      heading="Create an app"
                      paragraph="API calls are attached to apps, create an app to successfully call an API."
                      link={
                        permittedLinks(userDetails?.permissions?.appPermissions)
                          ? Constants.PAGES_URL.DEVELOPERS_CONFIGURATION
                          : '/quick-actions'
                      }
                    />
                    <QuickActionsChecklist
                      imageComponent={
                        userDetails?.checklist?.idCheck ? (
                          <Checked />
                        ) : (
                          <Unchecked />
                        )
                      }
                      heading="Perform an Identity check"
                      paragraph="LookUp an identity."
                      link={
                        permittedLinks(
                          userDetails?.permissions?.lookupPermissions,
                        )
                          ? Constants.PAGES_URL.INDIVIDUAL_LOOKUP_SINGLE
                          : '/quick-actions'
                      }
                    />
                  </div>
                </div>
              )}

              <QuickActionsDynamicCard />
            </div>
          </div>
        )}
      </QuickActionsWrapper>
    </>
  );
}

export default connect(state => state, Actions)(QuickActions);
