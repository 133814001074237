/* eslint-disable react/jsx-indent */

import React, {useContext, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  back,
  chevronRight,
  closeModal,
  corruptedFile,
  sandbox_dummy,
  smiley,
} from '../../assets/images/images';
import {Constants} from '../../helpers';
import {checkKey} from '../../helpers/constants/ckeckLookupKeys';
import * as Actions from '../../store/actions';
import {Loader, Modal, PrimaryButton} from '../../components';
import {getCookieValue} from '../../helpers/getCookie';
import {SANDBOX_DUMMY} from './SandBoxDummyLookup';
import moment from 'moment/moment';
import {LogoContext, PageContext} from '../../helpers/context';
import {
  generatePDF,
  generatePDFStructureForEasyLookup,
} from './generatePDFStructureForEasyLookup';
import {convertSVGToBase64} from '../../helpers/convertSVGToBase64';
import {affiliateskeysOfInterest, ignoreKeys, lookupNames} from './LookupUtils';

const EasyLookupDetails = ({
  changeLookupPage,
  clearLookupDetails,
  lookup: {lookup, loading},
  open,
  setOpen,
  auth: {userDetails},
}) => {
  const logo = useContext(LogoContext);
  const {setPageName} = useContext(PageContext);
  useEffect(() => {
    setPageName('lookup');
  }, [setPageName]);

  const [svgBase64, setBase64URL] = useState('');

  const [showAffiliateDetails, setShowAffiliateDetails] = useState(false);

  const toggleAffiliateDetails = () => {
    setShowAffiliateDetails(prevState => !prevState);
  };

  const formatKey = key =>
    key
      .split(/(?=[A-Z])|_/)
      .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
      .join(' ');

  useEffect(() => {
    convertSVGToBase64().then(logo => setBase64URL(logo));
  }, []);

  const allCountryIds = [
    ...Constants.lookups.nigeriaId,
    ...Constants.lookups.ghanaId,
    ...Constants.lookups.kenyaId,
    ...Constants.lookups.ugandaId,
  ];
  const ref = useRef();
  const env = getCookieValue('env');

  const lookupType = allCountryIds.find(id => {
    const lookupId =
      id?.value?.toLowerCase() === 'basic'
        ? 'basic_phone'
        : id?.value?.toLowerCase();
    return lookupId === lookup?.type;
  });
  const lookupData =
    env !== 'Sandbox' ? (lookup && lookup[lookup?.type]) || {} : SANDBOX_DUMMY;

  Object.keys(lookupData).forEach(key => {
    if (
      lookupData[key] === '' ||
      lookupData[key] === null ||
      lookupData[key] === ' '
    ) {
      delete lookupData[key];
    }
  });
  const lookupKeys = Object.keys(lookupData).map(item => item);

  const data =
    !loading &&
    lookupKeys
      ?.map(key => {
        if (key === 'affiliates') {
          return lookupData[key].map((affiliate, index) => {
            const affiliateDetails = affiliateskeysOfInterest
              .map(affiliateKey => {
                const formattedKey = formatKey(affiliateKey);
                if (affiliate[affiliateKey]) {
                  return `${formattedKey}: ${affiliate[affiliateKey]}`;
                } else {
                  return `${formattedKey}: -`;
                }
              })
              .join('\n');

            return {[`Affiliate ${index + 1}`]: affiliateDetails};
          });
        } else {
          return {[checkKey(key)]: lookupData[key]};
        }
      })
      .flat();

  const lookupImage = `${
    (lookupData?.photo &&
      !['Not Available'].includes(lookupData.photo) &&
      lookupData?.photo) ||
    (lookupData?.image && lookupData?.image) ||
    (lookupData?.image_url && lookupData?.image_url) ||
    (lookupData?.picture && lookupData?.picture) ||
    ''
  }`;

  const content =
    !loading &&
    data?.length > 0 &&
    generatePDFStructureForEasyLookup({
      lookupData: data,
      logo: svgBase64,
      ...(lookupImage
        ? {lookupImage: `data:image/png;base64,${lookupImage}`}
        : {}),
      lookupType: lookupType?.label,
      companyName: userDetails?.company?.name,
    });

  const pdfFooter = {
    name: lookupData?.caller_name || lookupData?.caller_email,
    generated: lookupData?.date_created,
    logo: svgBase64,
  };

  const downloadPDF = () => {
    generatePDF(content, pdfFooter, lookupType?.label);
  };

  const companyNames = ['company_name', 'Name_of_Company'];
  const firstNames = lookupNames.firstNames.filter(n => lookupData[n]);
  const lastNames = lookupNames.lastNames.filter(n => lookupData[n]);

  return (
    <>
      {lookup?.type === 'company_search' ? (
        <Modal
          extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
          modalPosition="justify-center sm:justify-end"
          modalTitle={
            <div className="flex items-center gap-1">
              <h3 className="text-base font-medium text-grey">
                {lookup?.company_search?.name}
              </h3>
              <span className="w-5 h-5 bg-brandBlue flex items-center justify-center rounded-full text-white text-xs font-medium">
                {lookup?.company_search?.detail?.length || 0}
              </span>
            </div>
          }
          show={open}
          onClose={() => {
            setOpen(false);
            changeLookupPage(1);
          }}
          setOpen={setOpen}
        >
          {loading ? (
            <Loader height={25} />
          ) : (
            <div className="mt-6 flex flex-col gap-4">
              {lookup?.company_search?.detail?.length > 0 ? (
                lookup?.company_search?.detail?.map((result, index) => (
                  <Link
                    to={`/easy-lookup/business/global-search/result/${result.internationalNumber}`}
                    key={index}
                    className="p-6 bg-white80 rounded flex items-center justify-between w-full card-link"
                    onClick={() => {
                      setOpen(false);
                      changeLookupPage(1);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <img src={smiley} alt="" width={24} height={24} />
                      <p className="text-base text-grey ">
                        {result.name || result?.Name}
                      </p>
                    </div>
                    <p className="text-sm text-grey pr-4">
                      {result.match_type || result?.NameMatchScore}
                    </p>
                  </Link>
                ))
              ) : (
                <div className="p-4  w-full ">
                  <div className="flex flex-col items-center justify-center py-12 mt-6 bg-white80">
                    <img src={corruptedFile} alt="" width={48} height={48} />
                    <p className="max-w-[179px] mt-2 text-sm text-center text-body">
                      We did not find any information on this company
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal>
      ) : (
        <Modal
          show={open}
          onClose={() => {
            setOpen(false);
            changeLookupPage(1);
          }}
          extraModalClasses="max-w-[581px] rounded-t-none sm:rounded-lg absolute bottom-0 sm:relative h-fit max-h-full overflow-y-auto lookup-modal"
          modalPosition="justify-center sm:justify-end"
          fullScreenModal
        >
          <div className="p-4 sm:p-10 sm:pb-6">
            <div className="hidden print:flex print:items-center print:justify-between">
              <img src={logo} alt="" style={{maxHeight: '40px'}} />
              <h3 className="text-grey text-base font-medium">
                {lookupType?.label} verification
              </h3>
            </div>
            <button
              onClick={() => {
                setOpen(false);
                changeLookupPage(1);
              }}
              className="flex items-center gap-2 sm:hidden bg-white80 p-2 pl-0 print:hidden"
            >
              <img src={back} alt="" />
              <span className="text-small text-grey80 font-medium">
                Back to Easy Lookup
              </span>
            </button>
            <header className="mt-6 sm:mt-0 flex items-center justify-center sm:justify-between print:hidden">
              <div className="flex items-center gap-2 flex-col sm:flex-row">
                <h3 className="text-grey text-base font-medium">
                  {lookupType?.label} verification
                </h3>
                <div className="flex items-center gap-2">
                  <div className="uppercase py-1 px-2 text-xs text-success font-medium bg-success20 rounded-full w-fit">
                    individual
                  </div>
                  <div className="uppercase py-1 px-2 text-xs text-success20 font-medium bg-success rounded-full w-fit">
                    completed
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  setOpen(false);
                  setTimeout(() => {
                    changeLookupPage(1);
                    clearLookupDetails();
                  }, 1000);
                }}
                className="hidden sm:block"
              >
                <div>
                  <img src={closeModal} alt="" />
                </div>
              </button>
            </header>

            {loading ? (
              <div className="h-96 mt-8 flex flex-col">
                <Loader />
              </div>
            ) : (
              !loading &&
              lookupData && (
                <div className="" ref={ref}>
                  <div className="mt-8 flex flex-col items-center">
                    {env !== 'Sandbox' ? (
                      <>
                        {lookupImage ? (
                          <img
                            src={
                              lookupImage?.startsWith('https')
                                ? lookupImage
                                : `data:image/png;base64,${lookupImage}`
                            }
                            alt="User Image"
                            width={80}
                            height={80}
                            className="text-small"
                          />
                        ) : (
                          <div className="h-[110px] w-[110px] rounded-full bg-brandBlue40  text-brandBlue flex justify-center items-center text-xxl ">
                            {lastNames.map(
                              name =>
                                `${lookupData[name]?.substr(0, 1) || '-'}`,
                            )}
                            {firstNames.map(
                              name =>
                                `${
                                  companyNames.includes(name)
                                    ? lookupData[name]
                                        ?.split(' ')[1]
                                        ?.substr(0, 1)
                                    : lookupData[name]?.substr(0, 1) || '-'
                                }`,
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <img
                        src={sandbox_dummy}
                        alt="Photo"
                        width={80}
                        height={80}
                        className="text-small"
                      />
                    )}

                    <p className="mt-4 text-sm text-grey font-medium">
                      {lastNames.map(
                        name =>
                          `${
                            companyNames.includes(name)
                              ? lookupData[name]?.split(' ')[0]
                              : lookupData[name] || '-'
                          } `,
                      )}
                      {firstNames.map(
                        name =>
                          `${
                            ['Name_of_Company'].includes(name)
                              ? lookupData[name]
                                  ?.split(' ')
                                  ?.slice(1)
                                  ?.join(' ')
                              : lookupData[name] || '-'
                          }`,
                      )}
                    </p>
                  </div>

                  <div className="mt-8 px-4 py-4 sm:p-6 bg-white80 -mx-4 sm:mx-0 rounded">
                    {!loading &&
                      lookupKeys &&
                      lookupKeys?.map(key => (
                        <React.Fragment key={key}>
                          {key &&
                          ignoreKeys.includes(key?.toLowerCase()) ? null : (
                            <React.Fragment key={key}>
                              {key &&
                              !ignoreKeys.includes(key?.toLowerCase()) ? (
                                <div
                                  key={key}
                                  className={`py-4 px-4 sm:px-6 flex items-center justify-between ${
                                    key === 'affiliates' && showAffiliateDetails
                                      ? ''
                                      : 'border-b border-grey60'
                                  } `}
                                >
                                  <p className="text-xs text-grey font-medium uppercase text-left">
                                    {key === 'affiliates'
                                      ? 'Number of Affiliates'
                                      : checkKey(key)}
                                  </p>

                                  <div className="text-small text-body w-100 text-right">
                                    {key === 'affiliates' && (
                                      <div className="flex items-center gap-1">
                                        <button
                                          type="button"
                                          className="text-sm font-medium text-brandBlue cursor-pointer -mb-1"
                                          onClick={toggleAffiliateDetails}
                                        >
                                          {showAffiliateDetails ? (
                                            <>
                                              <span className="text-body">
                                                {lookupData[key]?.length}{' '}
                                              </span>
                                              <span>minimize details</span>
                                            </>
                                          ) : (
                                            'Expand details'
                                          )}
                                        </button>
                                        <img
                                          src={chevronRight}
                                          alt="right arrow"
                                          width={16}
                                          height={16}
                                        />
                                      </div>
                                    )}

                                    {key !== 'affiliates' && (
                                      <span>
                                        {(lookupKeys &&
                                        typeof lookupData[key] !== 'object' &&
                                        ['createdAt', 'updatedAt'].includes(key)
                                          ? moment(lookupData[key]).format(
                                              'DD MMM YYYY h:m',
                                            )
                                          : lookupData[key]) || '-'}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ) : null}

                              {key === 'affiliates' && showAffiliateDetails && (
                                <div className="flex flex-col gap-2 w-full px-6">
                                  {lookupData[key]?.map((affiliate, index) => (
                                    <div
                                      key={index}
                                      className="bg-[#F3F3F3] py-4 px-2.5 flex flex-col items-start gap-4 rounded-lg"
                                    >
                                      <p className="uppercase text-grey text-xs font-medium">{`Affiliate ${
                                        index + 1
                                      }`}</p>

                                      <div className="flex flex-col gap-2 w-full">
                                        {affiliateskeysOfInterest.map(
                                          detailKey => (
                                            <div
                                              key={detailKey}
                                              className="flex items-center justify-between gap-6 border-b border-grey60 w-full py-2"
                                            >
                                              <p className="uppercase text-xs font-medium text-[#5E5E5E]">
                                                {detailKey === 'name'
                                                  ? 'Full name'
                                                  : formatKey(detailKey)}
                                              </p>
                                              <p className="text-sm font-medium text-grey text-end">
                                                {affiliate[detailKey] || '-'}
                                              </p>
                                            </div>
                                          ),
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              )
            )}
          </div>

          {!loading && lookup && (
            <footer
              className="rounded-b-xl py-2 sm:py-6 px-4 sm:px-0 bg-white80 w-full sticky bottom-0 print:hidden"
              style={{boxShadow: '0px -1px 8px rgba(51, 52, 66, 0.25)'}}
            >
              {!loading && (
                <div className=" print:flex print:items-center print:justify-between text-xs text-body uppercase mt-2 mb-6 px-[5.88%]">
                  <span>
                    Performed by:{' '}
                    {lookupData?.caller_name || lookupData?.caller_email
                      ? lookupData?.caller_name || lookupData?.caller_email
                      : '-'}
                  </span>
                  <span className="ml-8">
                    Generated:{' '}
                    {lookupData?.date_created
                      ? moment(lookupData?.date_created).format(
                          'MMM DD, YYYY hh:mmA',
                        )
                      : '-'}
                  </span>
                </div>
              )}
              <PrimaryButton
                buttonText="Download as PDF"
                className="w-full sm:max-w-sm"
                onClick={downloadPDF}
              />
            </footer>
          )}
        </Modal>
      )}
    </>
  );
};
export default connect(state => state, Actions)(EasyLookupDetails);
