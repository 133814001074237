import React from 'react';

const CopyIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      style={{
        fill: 'var(--color-primary)',
      }}
      d="M4.083 3.5V1.75a.583.583 0 0 1 .584-.583h7a.583.583 0 0 1 .583.583v8.167a.583.583 0 0 1-.583.583h-1.75v1.75a.585.585 0 0 1-.588.584H2.337a.585.585 0 0 1-.587-.584l.002-8.166c0-.322.262-.584.587-.584h1.744Zm1.167 0h4.667v5.834h1.166v-7H5.25V3.5Z"
    />
  </svg>
);
export {CopyIcon};
