import React, {useState} from 'react';
import {Modal, PrimaryButton} from '../../../../components';
import {closeModal} from '../../../../assets/images/images';
import {useMutation, useQueryClient} from 'react-query';
import {addProject} from '../../../../requests/queries/reconciliations';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

const initialProject = {
  desc: '',
  name: '',
};
export default function AddProjectsModal({show, onClose}) {
  const [project, setProject] = useState(initialProject);
  const {userDetails} = useSelector(state => state?.auth);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {mutate, isLoading} = useMutation('add_project', addProject, {
    onSuccess: data => {
      toast.success('Project added successfully');
      queryClient.invalidateQueries('projects');
      sessionStorage.setItem('project_id', String(data?.entity?.id));
      const url = location.pathname.replace(/[^/]+$/, data?.entity?.id);
      navigate(url);
      setProject(initialProject);
      onClose(false);
    },
  });

  const handleChange = e => {
    const {name, value} = e.target;
    setProject(prev => ({...prev, [name]: value}));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (project?.name) {
        const data = {
          company_id: userDetails && userDetails?.company?.id,
          ...project,
        };

        await mutate(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      extraModalClasses="max-w-[480px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center sm:justify-end"
    >
      <div className="px-6 pt-6 pb-12">
        <div className="flex items-center justify-between w-full">
          <h3 className="font-medium text-base text-grey">Add project</h3>

          <button
            onClick={() => {
              onClose(false);
            }}
            className="hidden sm:block"
          >
            <img src={closeModal} alt="" />
          </button>
        </div>

        <form className="mt-6" onSubmit={handleSubmit}>
          <label htmlFor="project_name">
            <span className="text-sm text-body font-medium">
              Name of project
            </span>
            <input
              type="text"
              name="name"
              className="mt-2 placeholder:text-grey-200 placeholder:font-normal"
              id="project_name"
              placeholder="Input project name"
              onChange={handleChange}
              value={project?.name}
            />
          </label>

          <label htmlFor="project_description" className="mt-6">
            <span className="text-sm text-body font-medium">
              Project description{' '}
              <span className="text-[#858DAA]">(Optional)</span>{' '}
            </span>
            <textarea
              id="project_description"
              className="mt-2 h-40 placeholder:text-grey-200 placeholder:font-normal"
              name="desc"
              placeholder="Input project description"
              onChange={handleChange}
              value={project?.desc}
            />
          </label>

          <div className="flex items-center mt-6">
            <PrimaryButton
              buttonText="Submit"
              className="w-full rounded-lg"
              type="submit"
              loading={isLoading}
              disabled={!project?.name}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
}
