import {toast} from 'react-toastify';
import reduxApi from '../../helpers/reduxApi';
import posthog from 'posthog-js';
import mixpanel from 'mixpanel-browser';
import {getCookieValue} from '../../helpers/getCookie';

import {
  LOADING,
  SET_USER,
  UPDATED,
  SET_USER_PROFILE,
  REGENERATE_API_KEY,
  SET_AUDIT_TRAIL,
  SET_BILLINGS,
  GET_PAYMENT_DETAILS,
  GET_ACCOUNT_NUMBER,
  UPDATE_COMPANY_INFO,
  SET_USER_SETTINGS,
  BILLINGS_API_CALLS,
  SET_LIVE,
  M_APP_LOADING,
  DO_NOTHING,
  LOGOUT,
  LOGOUT_USER,
} from '../types';
import {PAGES_URL} from '../../helpers/constants';
const isReconcilo = localStorage.subdomain?.includes('reconcilo');

// import {getAllApps} from './apps';

export const loginUser = () =>
  reduxApi(
    '/admin/login/url',
    'GET',
    null,
    {
      report: true,
      success: data => {
        window.location.href = data?.url;
      },
    },
    {error: 'ERROR', loading: LOADING, responder: 'LOGIN_USER_START'},
  );
export const regenerateApiKey = () =>
  reduxApi(
    '/users/keys/generate',
    'PUT',
    {},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: REGENERATE_API_KEY});
      },
    },
    {error: 'ERROR', loading: LOADING, responder: REGENERATE_API_KEY},
  );

export const viewSecret = (payload, callback) =>
  reduxApi(
    '/users/keys/view/private',
    'POST',
    {...payload},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: REGENERATE_API_KEY});
        dispatch(getApiKeys());
        callback();
      },
    },
    {error: 'ERROR', loading: LOADING, responder: REGENERATE_API_KEY},
  );

export const changePassword = (data, success) =>
  reduxApi(
    '/users/password/change',
    'PUT',
    data,
    {
      success: () => {
        toast.success('Your password has been updated');
        success();
      },
    },
    {error: 'ERROR', loading: LOADING, responder: REGENERATE_API_KEY},
  );
export const setPassword = (data, success) =>
  reduxApi(
    '/users/password/set',
    'PUT',
    data,
    {
      success: () => {
        toast.success('Your password has been set');
        success();
      },
    },
    {error: 'ERROR', loading: LOADING, responder: REGENERATE_API_KEY},
  );
export const getApiKeys = () =>
  reduxApi(
    '/users/keys/view',
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: REGENERATE_API_KEY});
      },
    },
    {error: 'ERROR', loading: LOADING, responder: REGENERATE_API_KEY},
  );
export const getUserSettings = () =>
  reduxApi(
    '/users/settings',
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: SET_USER_SETTINGS});
      },
    },
    {error: 'ERROR', loading: LOADING, responder: SET_USER_SETTINGS},
  );
export const getUserProfile = () =>
  reduxApi(
    '/users/me/profile',
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        const {user, ...rest} = data;
        dispatch({payload: user, type: SET_USER});
        dispatch(getUserSettings());
        dispatch({
          payload: {...rest},
          type: SET_USER_PROFILE,
        });
        const env = getCookieValue('env');
        const live = !rest.company.verified ? false : env === 'Production';
        dispatch({payload: live, type: SET_LIVE});

        const fullName =
          (user && `${user?.first_name} ${user?.last_name}`) || '-';
        if (!isReconcilo) {
          posthog.identify((user && user?.id) || 'user', {
            name: fullName || '-',
            email: (user && user?.email) || '-',
            company: rest?.company?.name || '-',
            environment: process.env.REACT_APP_APP_ENV_MODE || '-',
            mode: env,
          });
          posthog.setPersonPropertiesForFlags({company: rest?.company?.name});
        }
        if (isReconcilo) {
          mixpanel.identify((user && user?.id) || 'USER_ID');
          mixpanel.people.set({
            $name: fullName,
            $email: (user && user?.email) || '-',
            company: rest?.company?.name || '-',
            environment: process.env.REACT_APP_APP_ENV_MODE || '-',
            mode: env,
            // Add anything else about the user here
          });
        }
      },
    },
    {error: 'ERROR', loading: LOADING, responder: SET_USER},
  );

export const updateUserProfile = data =>
  reduxApi(
    '/users/user',
    'PUT',
    {...data},
    {
      report: data.first_time_user !== false,
      success: (data, dispatch) => {
        dispatch({payload: data, type: SET_USER});
        toast.success('Your details have been updated');
        // if (data.first_time_user === false)
        //   toast.success('Your details have been updated');
      },
    },
    {
      error: 'ERROR',
      loading: data.first_time_user !== false ? LOADING : DO_NOTHING,
      responder: SET_USER,
    },
  );
export const getAuditTrails = data =>
  reduxApi(
    `/users/audit-trail?page=${data?.page || 1}&limit=${data?.limit || 10}${
      data?.user ? `&user=${data?.user}` : ''
    }${data?.start ? `&start=${data?.start}` : ''}
    ${data?.end ? `&end=${data?.end}` : ''}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: SET_AUDIT_TRAIL});
      },
    },
    {error: 'ERROR', loading: LOADING, responder: SET_AUDIT_TRAIL},
  );
export const getBillings = ({page, limit, apps, type, start, end}) =>
  reduxApi(
    `/payments/billings?page=${page || 1}&limit=${limit || 10}${
      type ? `&type=${type}` : ''
    }${start ? `&start=${start}` : ''}${end ? `&end=${end}` : ''}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        const noFilter = !type && !apps && !start && !end;
        const billingData =
          noFilter && data?.api_logs?.length === 0 ? undefined : data;
        dispatch({payload: billingData, type: SET_BILLINGS});
      },
    },
    {error: 'ERROR', loading: LOADING, responder: SET_AUDIT_TRAIL},
  );
export const getBillingsApiCalls = ({
  page,
  limit,
  apps,
  services,
  start,
  end,
}) =>
  reduxApi(
    `/logs/services?priced=true&page=${page || 1}&limit=${limit || 10}${
      apps ? `&apps=${apps}` : ''
    }${services ? `&services=${services}` : ''}${
      start ? `&start=${start}` : ''
    }${end ? `&end=${end}` : ''}`,
    'GET',
    null,
    {
      report: true,
      success: (data, dispatch) => {
        const noFilter = !services && !apps && !start && !end;
        const billingApiData =
          noFilter && data?.api_logs?.length === 0 ? undefined : data;

        dispatch({payload: billingApiData, type: BILLINGS_API_CALLS});
      },
    },
    {error: 'ERROR', loading: LOADING, responder: SET_AUDIT_TRAIL},
  );
export const exportBillings = ({type, start, end}) =>
  reduxApi(
    `/payments/billings/export?page=1${type ? `&type=${type}` : ''}${
      start ? `&start=${start}` : ''
    }${end ? `&end=${end}` : ''}`,
    'GET',
    null,
    {
      report: true,
      success: data => {
        toast.success(data?.message);
      },
    },
    {error: 'ERROR', loading: M_APP_LOADING, responder: ''},
  );
export const exportAuditLogs = user =>
  reduxApi(
    `/users/audit-trail/export${user ? `?user=${user}` : ''}`,
    'GET',
    null,
    {
      report: true,
      success: data => {
        const {records} = data;
        const a = document.createElement('a');
        a.href = records;
        a.download = records.split('/').pop();
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(records);
        a.remove();
        // toast.success('');
      },
    },
    {error: 'ERROR', loading: M_APP_LOADING, responder: ''},
  );

export const verifyPayment = data =>
  reduxApi(
    '/payments/verify',
    'POST',
    {...data},
    {
      report: true,
      success: (data, dispatch) => {
        toast.success('Payment Successful');
        dispatch(getUserProfile());
      },
    },
    {error: 'ERROR', loading: LOADING, responder: UPDATED},
  );
export const startPayment = data =>
  reduxApi(
    '/payments/get_details',
    'POST',
    {...data},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_PAYMENT_DETAILS});
      },
    },
    {error: 'ERROR', loading: LOADING, responder: GET_PAYMENT_DETAILS},
  );
export const getAccountNumber = () =>
  reduxApi(
    '/users/ngn_wallet/create',
    'GET',
    {},
    {
      success: (data, dispatch) => {
        dispatch({payload: data, type: GET_ACCOUNT_NUMBER});
      },
    },
    {
      error: 'ERROR',
      loading: LOADING,
      responder: GET_ACCOUNT_NUMBER,
      report: false,
    },
  );
export const requestCompanyVerification = () =>
  reduxApi(
    `/users/company/verify`,
    'GET',
    null,
    {
      success: (data, dispatch) => {
        toast.success('!!!');
        dispatch(getUserProfile());
      },
    },
    {error: 'ERROR', loading: LOADING, responder: DO_NOTHING, report: false},
  );
export const updateCompanyInfo = companyData =>
  reduxApi(
    '/users/company',
    'PUT',
    {...companyData},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({payload: data, type: UPDATE_COMPANY_INFO});
        toast.success(
          companyData.wallet_limit
            ? 'Your account threshold has been set'
            : 'Your verification request has been sent',
        );
        dispatch(requestCompanyVerification());
        dispatch(getUserProfile());
      },
    },
    {error: 'ERROR', loading: LOADING, responder: UPDATE_COMPANY_INFO},
  );
const addInterests = (interests, closeModal) =>
  reduxApi(
    '/users/interests',
    'PUT',
    {...interests},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch({type: UPDATED});
        dispatch({payload: null, type: SET_LIVE});
        localStorage.setItem('env', 'Sandbox');
        toast.success('Successfully added company!');
        closeModal();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
    },
    {error: 'ERROR', loading: LOADING, responder: UPDATED},
  );
export const addNewCompany = (companyData, closeModal) =>
  reduxApi(
    '/users/company/create',
    'POST',
    {name: companyData?.company},
    {
      report: true,
      success: (data, dispatch) => {
        dispatch(addInterests(companyData?.interests, closeModal));
      },
    },
    {error: 'ERROR', loading: LOADING, responder: UPDATE_COMPANY_INFO},
  );
export const switchCompany = id =>
  reduxApi(
    `/users/company/switch/${id}`,
    'PUT',
    {},
    {
      report: true,
      success: (data, dispatch) => {
        const subDomainUser = localStorage.subdomain;

        dispatch({type: UPDATED});
        dispatch({payload: null, type: SET_LIVE});
        localStorage.setItem('env', 'Sandbox');
        localStorage.removeItem('state');
        sessionStorage.removeItem('project_id');
        window.location.href = subDomainUser
          ? PAGES_URL.DEVELOPERS_CONFIGURATION
          : PAGES_URL.QUICKACTIONS;
        toast.success(data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
    },
    {error: 'ERROR', loading: LOADING, responder: SET_LIVE},
  );

export const logout = () => dispatch => {
  dispatch({type: LOGOUT});
  dispatch({type: LOGOUT_USER});
  localStorage.clear();
  window.location.href = PAGES_URL.SIGNIN;
};
