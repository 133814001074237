import React from 'react';
import {connect} from 'react-redux';
import * as Actions from '../../store/actions';
import {chevronRight, quickActionsIc} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';

function QuickActionsWrapper({auth: {userDetails}, children, topup}) {
  const welcomeText =
    userDetails?.company?.logo && userDetails?.company?.color_code
      ? 'Welcome'
      : 'Welcome to Dojah';

  return (
    <DashboardLayout
      xlRightPadding="xl:pr-[139px]"
      topPadding="pt-16"
      breadCrumbs={
        <div className="flex items-center">
          <img src={quickActionsIc} alt="" width={18} height={18} />
          <p className="ml-2 -mb-1 cursor-pointer">Quick actions</p>
          <img src={chevronRight} alt="" width={18} height={18} />
          <p className="-mb-1 cursor-pointer">Welcome</p>
        </div>
      }
    >
      <div className="flex items-start justify-between">
        <div className="flex flex-col gap-2">
          <h2 className="text-lg font-medium sm:text-xl text-grey">
            {welcomeText}
          </h2>
          <p className="text-base sm:text-sm text-body">
            Take quick actions to get started.
          </p>
        </div>

        {topup}
      </div>

      {children}
    </DashboardLayout>
  );
}

export default connect(state => state, Actions)(QuickActionsWrapper);
