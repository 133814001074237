import React, {memo} from 'react';
import {NavLink} from 'react-router-dom';
import {
  developersNav,
  configurationNav,
  profileNav,
  auditLogsNav,
  billingNav,
  settingsNav,
  developersMobileNav,
  configurationMobileNav,
  webhookMobileNav,
  webhookNav,
  transactionHistoryNav,
  transactionHistoryMobileNav,
  billingMobileNav,
  settingsMobileNav,
} from '../../assets/images/images';

import {Constants} from '../../helpers';
import {usePermissions} from '../../hooks/useSidbarPermissions';
import {reconciloNavLinks} from './constants';
import {NavigationItem, SubNavigationDropdown} from './components';

const ReconciloSideNavigations = memo(
  ({media, splitLocation, pathname, openDropdown, toggleSubRoute}) => {
    const {
      USER_PERMISSIONS,
      permittedLinks,
      canManageFlows,
      canReviewCases,
      canViewHistory,
      canViewApiCalls,
      manageAuditLogs,
      webhookPermission,
      projectId,
      modularisation: {
        audit_logs_services,
        billings_services,
        developers_services,
        profile_services,
      },
    } = usePermissions();

    return (
      <>
        <div className="flex flex-col">
          <div className="mt-2 flex flex-col gap-2 rounded lg:text-white text-grey">
            <div className="flex flex-col">
              {reconciloNavLinks?.map((link, idx) => (
                <NavigationItem
                  key={link?.label + idx}
                  media={media}
                  title={link?.label}
                  active={splitLocation[1] === ''}
                  to={link?.url.replace(':slug', projectId)}
                  webIcon={link?.imgSrc}
                  mobileIcon={link?.imgSrc}
                />
              ))}

              {/*<NavigationItem*/}
              {/*  media={media}*/}
              {/*  title="Overview"*/}
              {/*  active={splitLocation[1] === ''}*/}
              {/*  to={Constants.PAGES_URL.RECONCILIATION_OVERVIEW.replace(*/}
              {/*    ':slug',*/}
              {/*    projectId,*/}
              {/*  )}*/}
              {/*  webIcon={overviewReconciliationNav}*/}
              {/*  mobileIcon={overviewReconciliationNav}*/}
              {/*/>*/}
            </div>
          </div>
        </div>

        {(permittedLinks(USER_PERMISSIONS?.appPermissions) ||
          permittedLinks(USER_PERMISSIONS?.logPermissions)) &&
          developers_services && (
            <div className="mt-6 flex flex-col gap-2 rounded lg:text-white text-grey">
              <SubNavigationDropdown
                media={media}
                toggleSubRoute={() => toggleSubRoute('developers')}
                src={media ? developersNav : developersMobileNav}
                open={openDropdown === 'developers'}
                label="Developers"
              />

              {openDropdown === 'developers' && (
                <div className="flex flex-col">
                  {permittedLinks(USER_PERMISSIONS?.appPermissions) && (
                    <NavigationItem
                      media={media}
                      title="Configuration"
                      active={splitLocation[1] === ''}
                      to={Constants.PAGES_URL.DEVELOPERS_CONFIGURATION}
                      webIcon={configurationNav}
                      mobileIcon={configurationMobileNav}
                      inner
                    />
                  )}

                  {webhookPermission && (
                    <NavigationItem
                      media={media}
                      title="Webhooks"
                      active={splitLocation[1] === ''}
                      to={Constants.PAGES_URL.DEVELOPERS_WEBHOOK}
                      webIcon={webhookNav}
                      mobileIcon={webhookMobileNav}
                      inner
                    />
                  )}
                </div>
              )}
            </div>
          )}
        {billings_services && (canViewHistory || canViewApiCalls) && (
          <div className="mt-2 flex flex-col gap-2 rounded lg:text-white text-grey">
            <SubNavigationDropdown
              media={media}
              toggleSubRoute={() => toggleSubRoute('billing')}
              src={media ? billingNav : billingMobileNav}
              open={openDropdown === 'billing'}
              label="Billing"
            />

            {openDropdown === 'billing' && (
              <div className="flex flex-col">
                {canViewHistory && canManageFlows && (
                  <NavigationItem
                    media={media}
                    title="Transaction history"
                    active={pathname === Constants.PAGES_URL.SETTINGS_BILLING}
                    to={Constants.PAGES_URL.SETTINGS_BILLING}
                    webIcon={transactionHistoryNav}
                    mobileIcon={transactionHistoryMobileNav}
                    inner
                  />
                )}
                {canReviewCases && canViewApiCalls && (
                  <NavigationItem
                    media={media}
                    title="API calls"
                    active={
                      pathname === Constants.PAGES_URL.SETTINGS_BILLING_PRICING
                    }
                    to={Constants.PAGES_URL.SETTINGS_BILLING_PRICING}
                    webIcon={billingNav}
                    mobileIcon={billingMobileNav}
                    inner
                  />
                )}
              </div>
            )}
          </div>
        )}
        <div className="flex flex-col mt-2">
          {(profile_services || audit_logs_services) && (
            <SubNavigationDropdown
              media={media}
              toggleSubRoute={() => toggleSubRoute('settings')}
              src={media ? settingsNav : settingsMobileNav}
              open={openDropdown === 'settings'}
              label="Settings"
            />
          )}

          {openDropdown === 'settings' && (
            <>
              <NavLink
                to={Constants.PAGES_URL.SETTINGS_PROFILE}
                className={`nav-item rounded flex lg:hidden items-center gap-2 pl-8 py-2 relative ${splitLocation[1]} === "" ? "active" : ""`}
                aria-current="page"
              >
                <img src={settingsMobileNav} alt="" width={18} height={18} />
                <span className="-mb-1 text-sm text-body">All settings</span>
              </NavLink>
              <div className="flex-col hidden lg:flex">
                {profile_services && (
                  <NavigationItem
                    media={media}
                    title="Profile"
                    active={splitLocation[1] === ''}
                    to={Constants.PAGES_URL.SETTINGS_PROFILE}
                    webIcon={profileNav}
                    mobileIcon={settingsMobileNav}
                    inner
                  />
                )}

                {Object.values(
                  USER_PERMISSIONS?.settingsPermissions || {},
                ).some(s => !!s) && (
                  <>
                    {USER_PERMISSIONS?.settingsPermissions
                      ?.viewAndDownloadAudit &&
                      audit_logs_services &&
                      manageAuditLogs && (
                        <NavigationItem
                          media={media}
                          title="Audit Logs"
                          active={splitLocation[1] === ''}
                          to={Constants.PAGES_URL.SETTINGS_AUDIT_LOGS}
                          webIcon={auditLogsNav}
                          mobileIcon={auditLogsNav}
                          inner
                        />
                      )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  },
);

export {ReconciloSideNavigations};
