const getStatusTextAndStyles = status =>
  ({
    successful: {styles: 'bg-success20 text-success', text: 'Successful'},
    completed: {styles: 'bg-success20 text-success', text: 'Successful'},
    pending: {styles: 'bg-[#FFD9512B] text-[#E4B303]', text: 'Pending'},
    expired: {styles: 'bg-[#6507082B] text-[#650708]', text: 'Expired'},
    failed: {styles: 'bg-[#A13E3F2B] text-status-404', text: 'Failed'},
    started: {styles: 'bg-batchBlue20 text-batchBlue', text: 'Created'},
    abandoned: {styles: 'bg-disabled', text: 'Abandoned'},
    ongoing: {styles: 'bg-[#FF681C2B] text-[#FF681C]', text: 'Ongoing'},
  }[status] || {styles: 'bg-grey4', text: 'Pending'});
const getStatusWithNumbers = number =>
  ({
    1: 'pending',
    2: 'successful',
    3: 'failed',
  }[number] || 'pending');

export {getStatusTextAndStyles, getStatusWithNumbers};
