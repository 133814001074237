import React from 'react';
import {filter, filterMobile, filterWhite} from '../assets/images/images';

export default function FilterButton({
  setOpenFilter,
  openFilter,
  filterCount = 0,
  className = '',
}) {
  return (
    <>
      <button onClick={() => setOpenFilter(!openFilter)}>
        <img
          src={filterMobile}
          alt="filter"
          className="md:hidden block w-8 h-8"
        />
      </button>
      <button
        onClick={() => setOpenFilter(!openFilter)}
        className={`${className} h-12 hidden md:flex items-center gap-2 p-[13px] pt-[14px] pb-3 font-medium outline outline-1 rounded focus-visible:outline-brandBlue ${
          filterCount === 0
            ? 'bg-white80 text-grey80 outline-grey60'
            : 'bg-brandBlue text-white outline-none'
        }`}
      >
        <img
          src={filterCount === 0 ? filter : filterWhite}
          alt=""
          width={24}
          height={24}
        />
        <span className="text-sm -mb-1">Filter</span>
        <span
          className={`${
            filterCount === 0 ? 'hidden' : 'flex'
          } items-center justify-center text-xs text-brandBlue w-[18px] h-[18px] bg-white rounded-full`}
        >
          {filterCount}
        </span>
      </button>
    </>
  );
}
