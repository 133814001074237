import React, {useEffect, useMemo} from 'react';
import {Filter} from '../../../../components';
import {useNavigate} from 'react-router-dom';
import {
  filterOutEmptyValues,
  generateQueryParams,
  parseUrlParams,
} from '../../../../helpers';
import {useAccounts} from '../hooks/useAccounts';
// const filterOptions = [
//   {
//     title: 'Status',
//     name: 'status',
//     values: [
//       {
//         label: 'Pending',
//         value: 'pending',
//         name: 'pending',
//       },
//       {
//         label: 'Verified',
//         value: 'verified',
//         name: 'verified',
//       },
//       {
//         label: 'Not Verified',
//         value: 'not Verified',
//         name: 'not Verified',
//       },
//       {
//         label: 'Failed',
//         value: 'failed',
//         name: 'failed',
//       },
//     ],
//   },
// ];

export const ReconFilters = ({
  limit,
  setQuery,
  setFilters,
  openFilter,
  setOpenFilter,
  query,
  initialFilters,
  slug,
  otherFilterOptions = [],
  noAccount,
  customClasses,
}) => {
  const navigate = useNavigate();
  const {accountSelectionData, isLoading: accountLoading} = useAccounts({slug});
  useEffect(() => {
    const apiParams = parseUrlParams();
    apiParams.limit = limit;
    const searchParams = new URLSearchParams(document.location.search);
    const pageParam = searchParams.get('page');
    if (pageParam) {
      setQuery({...query, page: pageParam});
    }
    if (Object.keys(apiParams).length > 1) {
      setFilters(apiParams);
    }
  }, [limit, setQuery, setFilters, query]);

  const handleFilter = (selectedOptions = false) => {
    const filteredOptions = filterOutEmptyValues(selectedOptions);
    const queryParams = generateQueryParams(filteredOptions);
    const {status} = filteredOptions;

    if (Array.isArray(status)) {
      filteredOptions.status = status.join(',');
    }
    setQuery({...query, page: 1});
    let baseUrl = '';

    if (queryParams) {
      baseUrl += `?${queryParams}`;
    }
    navigate(baseUrl);

    setQuery({
      ...query,
      page: 1,
    });

    const {start, end, ...rest} = filteredOptions;
    setFilters({
      ...rest,
      date_from: filteredOptions?.start,
      date_to: filteredOptions?.end,
    });
    setOpenFilter(false);
  };

  const accountFilters = useMemo(
    () =>
      !noAccount && !accountLoading && accountSelectionData.length > 0
        ? [{title: 'Account', name: 'account', values: accountSelectionData}]
        : [],
    [accountLoading, accountSelectionData, noAccount],
  );

  const handleResetFilter = () => {
    navigate('');
    setQuery({...query, page: 1});
    setFilters(initialFilters);
    setOpenFilter(true);
  };

  return (
    <Filter
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      handleFilter={handleFilter}
      resetUrl={handleResetFilter}
      filterOptions={[
        ...(otherFilterOptions && noAccount
          ? [...otherFilterOptions]
          : [...accountFilters, ...otherFilterOptions]),
      ]}
      customClasses={customClasses}
    />
  );
};
